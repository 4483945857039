/*
 * form.js - Adds signature widget to sao
 */
'use strict';

Sao.View.Form.Signature = Sao.class_(Sao.View.Form.Widget, {
    class_: 'form-signature',
    init: function(view, attributes) {
        Sao.View.Form.Signature._super.init.call(this, view, attributes);
        this.el = jQuery('<div/>', {
            'class': this.class_
        });
        this.canvas = jQuery('<canvas/>', {
            'class': this.class_
        }).appendTo(this.el);
        this.canvas.change(this.focus_out.bind(this));
        var button = jQuery('<button/>', {
            'class': 'btn btn-default btn-sm form-control',
            'type': 'button'
        }).appendTo(this.el);
        button.append(
            Sao.common.ICONFACTORY.get_icon_img('tryton-clear'));
        button.click(this.clear.bind(this));
        this.signature_pad = new SignaturePad(this.canvas[0]);
        this.resize_function = this.resize.bind(this);

        // Resize when added to the DOM or the window changes size
        var resize_function = this.resize_function;
        var observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                var new_nodes = mutation.addedNodes;
                if (new_nodes !== null) {
                    new_nodes.forEach(function(node) {
                        var signature = node.getElementsByClassName(
                            'form-signature');
                        if (signature.length >= 1) {
                            resize_function();
                            observer.disconnect();
                        }
                    });
                }
            });
        });
        observer.observe(document.body, {subtree: true, childList: true});
        window.addEventListener('resize', this.resize_function);
    },
    set_value: function() {
        this.field.set_client(this.record, this.get_value());
    },
    get_value: function() {
        if (this.signature_pad.isEmpty()) {
            return null;
        }

        var data = this.signature_pad.toDataURL("image/svg+xml");
        var svg = '<?xml version="1.0"?>' + window.atob(data.split(',')[1]);
        var array = new Uint8Array(svg.length);
        for (var i=0; i<svg.length; i++) {
            array[i] = svg.charCodeAt(i);
        }
        return array;
    },
    display: function() {
        Sao.View.Form.Signature._super.display.call(this);
    },
    set_readonly: function(readonly) {
        Sao.View.Form.Signature._super.set_readonly.call(this, readonly);
        if (readonly) {
            this.signature_pad.off();
        } else {
            this.signature_pad.on();
        }
    },
    focus: function() {
        this.canvas.focus();
    },
    resize: function() {
        var ratio =  Math.max(window.devicePixelRatio || 1, 1);
        var widths = [];
        var heights = [];

        if (!document.contains(this.canvas[0])) {
            window.removeEventListener('resize', this.resize_function);
            return;
        }

        this.canvas.parents().each(function() {
            widths.push(this.clientWidth);
        });
        this.canvas.parents().each(function() {
            heights.push(this.clientHeight);
        });

        var width = Math.min.apply(null, widths) - 12;
        this.canvas.width(width);
        this.canvas[0].width = width * ratio;

        var height = Math.min.apply(null, heights) - 12;
        this.canvas.height(height);
        this.canvas[0].height = height * ratio;

        this.canvas[0].getContext("2d").scale(ratio, ratio);

        this.clear();
    },
    clear: function() {
        this.signature_pad.clear();
        if (this.field !== undefined) {
            this.field.set_client(this.record, null);
        }
    }
});

Sao.View.FormXMLViewParser.WIDGETS.signature = Sao.View.Form.Signature;
